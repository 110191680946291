body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 12px;
  padding: 20px;
  line-height: 1.2;
}

h1,
h2,
h3 {
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 10px 0;
  text-align: center;
}

#app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploader,
.page,
.page-input input,
.column,
.block textarea,
.submit {
  border-radius: 8px;
}

.page-input input,
.block textarea {
  background-color: #e7f7e8;
  border: solid 1px #ccc;
  display: block;
}

.uploader,
.page,
.column,
.submit {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 0 4px 0 rgba(0, 0, 0, 0.1);
}

.uploader,
.submit {
  color: black;
  outline: none;
  border: none;
  width: 100%;
  padding: 30px;
  font-weight: bold;
  cursor: pointer;
}

.uploader {
  background-color: #eee;
  height: 100px;
}

.uploader:disabled {
  background-color: #fff;
}

.uploader.drag {
  background-color: #ccc;
}

.uploader .state {
  font-style: italic;
  font-weight: normal;
}

.uploader .state.error {
  font-style: normal;
  font-weight: bold;
  color: red;
}

.guidelines {
  margin-top: 20px;
}

.guidelines ul {
  list-style-position: inside;
  line-height: 1.5;
  padding: 0;
  margin: 0;
}

.pages {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  margin: 10px -10px;
}

@media only screen and (max-width: 1024px) {
  .pages {
    flex-direction: column;
  }
}

.page {
  background-color: #fff;
  flex-grow: 1;
  flex-basis: 0%;
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.page-input {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.page-input label {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
}

.page-input input {
  flex-grow: 1;
  padding: 6px;
}

.columns {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  margin: -5px;
}

@media only screen and (max-width: 640px) {
  .columns {
    flex-direction: column;
  }
}

.column {
  background-color: #fff;
  flex-grow: 1;
  margin: 5px;
  padding: 10px;
  padding-bottom: 5px;
  flex-basis: 0%;
}

.block {
  margin: 10px 0;
  display: flex;
}

.block textarea {
  padding: 6px;
  width: 100%;
  border-radius: 8px 0px 0px 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  white-space: nowrap;
}

.block button {
  border-radius: 0px 8px 8px 0px;
  border: solid 1px #ccc;
  border-left: none;
  background-color: #f8f8f8;
  color: black;
  outline: none;
  width: 40px;
  font-weight: bold;
  cursor: pointer;
  color: black;
}

.submit {
  background-color: #7ab381;
  color: white;
}
